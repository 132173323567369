import React, { useEffect, useState } from 'react';
import { Label, Modal, Loader } from 'semantic-ui-react';
import { TextBox } from 'devextreme-react/text-box';
import { TextArea } from 'devextreme-react/text-area';
import { useForm } from "react-hook-form";
import { detail, insert, update } from "../../api/role";
export default ({ is_show_model, role_id, onSuccess, onCancel }) => {
    const [role, set_role] = useState(false);
    const [is_saving, set_is_saving] = useState(false);
    const { register, trigger, errors } = useForm();
    const handleSaveChanges = async datas => {
        trigger().then(async (isValid) => {
            if (isValid) {
                if (role_id > 0) {
                    set_is_saving(true);
                    const res = await update(role);
                    set_is_saving(false);
                    if (res.is_success) {
                        onSuccess();
                    }
                } else {
                    set_is_saving(true);
                    const res = await insert(role);
                    set_is_saving(false);
                    if (res.is_success) {
                        onSuccess();
                    }
                }
            }
        })
    }
    useEffect(() => {
        if (is_show_model) {
            reload();
        }
    }, [role_id, is_show_model]);
    const reload = async () => {
        console.log(role_id);
        if (role_id > 0) {
            const res = await detail(role_id);
            if (res.is_success) {
                set_role(res.data)
            }
        }
    }
    return (
        <div>

            <Modal open={is_show_model} size="mini" dimmer={"blurring"} onClose={onCancel}
                closeOnEscape={false}
                closeOnDimmerClick={false}>
                <Modal.Header>
                    {role_id > 0 ? <p>Cập nhật</p> : <p>Thêm mới</p>}
                </Modal.Header>
                <Modal.Content>
                    <form className="ui">
                        <input className="hide" name="id" defaultValue={role.id}></input>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="dx-field-label">Tên vai trò</div>
                                <br></br>
                                <TextBox value={role.name}
                                    stylingMode="outlined"
                                    onValueChanged={(e) => {
                                        set_role(Object.assign({},
                                            role,
                                            { name: e.value }));
                                        trigger("name");
                                    }}
                                />
                                <input name="name"
                                    className="hide"
                                    defaultValue={role.name}
                                    ref={register({ required: true })}></input>
                                {
                                    errors.name &&
                                    <Label basic color='red' pointing>
                                        Vui lòng điền tên vai trò
                          </Label>
                                }
                            </div>
                            <div className="col-md-12 padding-top-25">
                                <div className="dx-field-label">Diễn giải vai trò</div>
                                <br></br>
                                <TextArea value={role.ghi_chu}
                                    autoResizeEnabled={true}
                                    placeholder="Tóm tắt nội dung của vai trò này"
                                    stylingMode="outlined"
                                    onValueChanged={(e) => {
                                        set_role(Object.assign({},
                                            role,
                                            { ghi_chu: e.value }));
                                        trigger("ghi_chu");
                                    }}
                                />
                                <input name="ghi_chu"
                                    className="hide" defaultValue={role.ghi_chu}
                                    ref={register({ required: true })}></input>
                                {
                                    errors.ghi_chu &&
                                    <Label basic color='red' pointing>
                                        Vui lòng điền diễn giải ngắn ngọn cho vai trò này
                          </Label>
                                }
                            </div>
                        </div>
                    </form>
                </Modal.Content>
                <Modal.Actions>

                    <div>
                        <button onClick={onCancel} className="p-btn p-btn-sm">
                            Đóng
                    </button>
                        <button disabled={is_saving} onClick={handleSaveChanges} className="p-btn p-prim-col p-btn-sm margin-left-10">
                            {role_id > 0 ? "Cập nhật" : "Thêm mới"}
                            {is_saving && <Loader active inline size="mini" />}
                        </button>

                    </div>
                </Modal.Actions>
            </Modal>
        </div>
    );
};
