//product
export default {
    api_url: 'https://portal.3s.edu.vn/api',
    google_client_id: "465939677113-2uctg8vssc0mn7b6hc0b4fh62ggm9mdc.apps.googleusercontent.com",
    microsoft_client_id: "465a048a-4700-42c9-bcd4-727079abf7e5"
};
//uat
//export default {
//  api_url: 'http://103.152.165.45/api',
//  google_client_id: "465939677113-2uctg8vssc0mn7b6hc0b4fh62ggm9mdc.apps.googleusercontent.com",
//  microsoft_client_id: "465a048a-4700-42c9-bcd4-727079abf7e5"
//};

//dev
//  export default {
//     api_url: 'https://localhost:5001/api',
//     google_client_id: "465939677113-2uctg8vssc0mn7b6hc0b4fh62ggm9mdc.apps.googleusercontent.com",
//     microsoft_client_id: "9dcb2158-f365-4bef-85a5-7f8bb28cb186",
//     microsoft_client_id: "465a048a-4700-42c9-bcd4-727079abf7e5"

//  };