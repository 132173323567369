import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import React from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import './dx-styles.scss';
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';
import UnauthenticatedContent from './UnauthenticatedContent';
import 'semantic-ui-css/semantic.min.css';
import { Image } from 'semantic-ui-react';


const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}
function App() {
    const { user, loading, service } = useAuth();
    const query = useQuery();
    const redirect_url = query.get("service");
    const system_id = query.get("system_id");
    const { createLoginTicket } = useAuth();

    if (loading || (service != null && service !== "")) {
        return (
            <React.Fragment>
                <Image src="./images/3s.png" className="loading-login"></Image>
            </React.Fragment>
        );
    }

    if (user) {

        if (redirect_url == null) {
            return <Content />;
        } else {
            return <UnauthenticatedContent />;
        }
        //

    }

    return <UnauthenticatedContent />;
}

export default function () {
    const screenSizeClass = useScreenSizeClass();

    return (
        <Router>
            <AuthProvider>
                <NavigationProvider>
                    <div className={`app ${screenSizeClass}`}>
                        <App />
                    </div>
                </NavigationProvider>
            </AuthProvider>
        </Router>
    );
}
