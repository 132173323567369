import React, { useState } from 'react'
import { Confirm, Image, Card, Checkbox } from 'semantic-ui-react'
import {
    insert as insert_role_subsystem,
    remove as delete_role_subsystem
} from "../../api/role_subsystem";

export default ({ role_sub_system, onChanged, onClick, is_focused }) => {
    const [is_show_confirm, set_is_show_confirm] = useState(false);
    const [role_id, set_role_id] = useState(0);
    const [sub_system_id, set_sub_system_id] = useState(0);
    const handleSubSystemChange = async (role_id, sub_system_id, is_selected) => {
        set_role_id(role_id);
        set_sub_system_id(sub_system_id);
        if (is_selected) {
            const res = await insert_role_subsystem({
                role_id: role_id,
                sub_system_id: sub_system_id
            });
            onChanged(role_id, sub_system_id);
        } else {
            set_is_show_confirm(true);
        }
    }
    const remove = async (role_id, sub_system_id) => {
        const res = await delete_role_subsystem({
            role_id: role_id,
            sub_system_id: sub_system_id
        });
        set_is_show_confirm(false);
        onChanged(role_id, sub_system_id);
    }
    const handleOnClick = async (sub_system_id) => {
        onClick(sub_system_id);
    }
    return (
        <React.Fragment>
            <Card className="card-app" key={role_sub_system.id} onClick={() => { handleOnClick(role_sub_system.sub_system_id) }}>
                <Card.Content className="text-align-center">
                    {/* <Image src={'/images/' + x.icon} wrapped ui={false}  /> */}

                    <Image disabled={!role_sub_system.is_selected} src={'/images/' + role_sub_system.icon} className="app-icon" style={{ width: 70, height: 70 }} />
                    <Card.Header disabled={!role_sub_system.is_selected} className={"text-align-center " + (is_focused ? "card-app-name-focused" : "card-app-name")}>
                        {role_sub_system.name}
                    </Card.Header>
                    <br></br>
                    <Checkbox className={"app-selection-checkbox " + (!role_sub_system.is_selected ? "uncheck" : "")} checked={role_sub_system.is_selected}
                        onChange={
                            () => handleSubSystemChange(role_sub_system.role_id, role_sub_system.sub_system_id, !role_sub_system.is_selected)
                        }>

                    </Checkbox>
                </Card.Content>
            </Card>
            <Confirm
                open={is_show_confirm}
                dimmer={"blurring"}
                header='Lưu ý'
                content={
                    () => {
                        return <div className="padding-2rem">
                            <p>Bạn có chắc chắn muốn bỏ chọn ứng dụng này khỏi vai trò</p>
                        </div>
                    }
                }
                size='mini'
                cancelButton={() => {
                    return (
                        <button onClick={() => { set_is_show_confirm(false) }} className="p-btn p-btn-sm">
                            Đóng
                        </button>
                    );
                }}
                confirmButton={() => {
                    return (
                        <button onClick={() => { remove(role_id, sub_system_id) }} className="p-btn p-btn-sm p-strawberry-col margin-left-10 p-white-color">
                            Bỏ hệ thống
                        </button>
                    );
                }}
            /></React.Fragment>

    );

}