import React, { useEffect, useState } from 'react';
import { Label, Modal, Loader } from 'semantic-ui-react';
import { TextBox } from 'devextreme-react/text-box';
import Form, {
    SimpleItem,
    GroupItem
} from 'devextreme-react/form';
import { useForm } from "react-hook-form";
import { detail, insert, update } from "../../api/user";
import { select_all as campus_select_all } from "../../api/campus";
import { select_all as role_select_all } from "../../api/role";
import { Switch } from 'devextreme-react/switch';
import { TagBox } from 'devextreme-react/tag-box';
import notify from 'devextreme/ui/notify';
export default ({ is_show_model, user_id, onSuccess, onCancel }) => {
    const [user, set_user] = useState({});
    const [roles, set_roles] = useState([]);
    const [campus, set_campus] = useState([]);
    const [is_saving, set_is_saving] = useState(false);
    const { register, trigger, errors } = useForm();
    const handleSaveChanges = async datas => {
        trigger().then(async (isValid) => {
            if (isValid) {
                if (user.id > 0) {
                    set_is_saving(true);
                    const res = await update(user);
                    set_is_saving(false);
                    if (res.is_success) {
                        onSuccess();
                    } else {
                        notify(res.message, 'error', 1000);
                    }
                } else {
                    set_is_saving(true);
                    const res = await insert(user);
                    set_is_saving(false);
                    if (res.is_success) {
                        onSuccess();
                    } else {
                        notify(res.message, 'error', 1000);
                    }
                }
            }
        })
    }
    useEffect(() => {
        console.log(user_id);
        if (is_show_model) {
            reload();
        }
    }, [user_id, is_show_model]);
    useEffect(() => {
        (async () => {
            const res = await role_select_all();
            if (res.is_success) {
                set_roles(res.data);
            }
        })();
        (async () => {
            const res = await campus_select_all();
            if (res.is_success) {
                set_campus(res.data);
            }
        })();
    }, []);
    const reload = async () => {

        if (user_id > 0) {
            const res = await detail(user_id);
            if (res.is_success) {
                set_user(res.data)
            }
        } else {
            set_user({});
        }
    }
    return (
        <div>

            <Modal open={is_show_model} size="mini" dimmer={"blurring"} onClose={onCancel}
                closeOnEscape={false}
                closeOnDimmerClick={false}>
                <Modal.Header>
                    {user.id > 0 ? <p>Cập nhật</p> : <p>Thêm mới</p>}
                </Modal.Header>
                <Modal.Content>
                    <form className="ui">
                        <input className="hide" name="id" defaultValue={user.id}></input>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="field-group">

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="field-set">
                                                <div className="field-label">Tên đăng nhập</div>
                                                <div className="field-input">
                                                    <TextBox value={user.username}
                                                        stylingMode="outlined"
                                                        className="small-textbox"
                                                        readOnly={user_id > 0}
                                                        onValueChanged={(e) => {
                                                            set_user(Object.assign({},
                                                                user,
                                                                { username: e.value }));
                                                            trigger("username");
                                                        }}
                                                    />
                                                    <input name="username"
                                                        className="hide"
                                                        defaultValue={user.username}
                                                        ref={register({ required: true })}></input>
                                                    {
                                                        errors.username &&
                                                        <Label basic color='red' pointing>
                                                            Vui lòng điền tên đăng nhập
                                                        </Label>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="field-set">
                                                <div className="field-label">Họ tên</div>
                                                <div className="field-input">
                                                    <TextBox value={user.full_name}
                                                        stylingMode="outlined"
                                                        className="small-textbox"
                                                        onValueChanged={(e) => {
                                                            set_user(Object.assign({},
                                                                user,
                                                                { full_name: e.value }));
                                                            trigger("full_name");
                                                        }}
                                                    />
                                                    <input name="full_name"
                                                        className="hide"
                                                        defaultValue={user.full_name}
                                                        ref={register({ required: true })}></input>
                                                    {
                                                        errors.full_name &&
                                                        <Label basic color='red' pointing>
                                                            Vui lòng điền họ tên
                                                        </Label>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="field-set">
                                                <div className="field-label">Email</div>
                                                <div className="field-input">
                                                    <TextBox value={user.email}
                                                        stylingMode="outlined"
                                                        className="small-textbox"
                                                        onValueChanged={(e) => {
                                                            set_user(Object.assign({},
                                                                user,
                                                                { email: e.value }));
                                                            trigger("email");
                                                        }}
                                                    />
                                                    <input name="email"
                                                        className="hide"
                                                        defaultValue={user.email}
                                                        ref={register({ required: true })}></input>
                                                    {
                                                        errors.email &&
                                                        <Label basic color='red' pointing>
                                                            Vui lòng điền email
                                                        </Label>
                                                    }
                                                </div>
                                            </div>
                                            <div className="field-set">
                                                <div className="field-label">Điện thoại</div>
                                                <div className="field-input">
                                                    <TextBox value={user.phone_number}
                                                        stylingMode="outlined"
                                                        className="small-textbox"
                                                        onValueChanged={(e) => {
                                                            set_user(Object.assign({},
                                                                user,
                                                                { phone_number: e.value }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="field-set">
                                        <div className="field-label">Vai trò</div>
                                        <div className="field-input">
                                            <TagBox dataSource={roles}
                                                displayExpr="name"
                                                valueExpr="id"
                                                stylingMode="outlined"
                                                value={user.role_ids}
                                                onValueChanged={(e) => {
                                                    set_user(Object.assign({},
                                                        user,
                                                        { role_ids: e.value }));
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="field-set">
                                        <div className="field-label">Cơ sở</div>
                                        <div className="field-input">
                                            <TagBox dataSource={campus}
                                                displayExpr="viet_tat"
                                                valueExpr="id"
                                                stylingMode="outlined"
                                                value={user.campus_ids}
                                                onValueChanged={(e) => {
                                                    set_user(Object.assign({},
                                                        user,
                                                        { campus_ids: e.value }));
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 padding-top-1rem">
                                <div className="form-group">
                                    <div className="field-set">
                                        <div className="field-label">Kích hoạt</div>
                                        <div className="field-input">
                                            <Switch value={user.is_active} onValueChanged={(e) => {
                                                set_user(Object.assign({}, user, { is_active: e.value }));
                                            }} />
                                            <input name="is_active"
                                                className="hide"
                                                defaultValue={user.is_active}></input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Content>
                <Modal.Actions>

                    <div>
                        <button onClick={onCancel} className="p-btn p-btn-sm">
                            Đóng
                        </button>
                        <button disabled={is_saving} onClick={handleSaveChanges} className="p-btn p-prim-col p-btn-sm margin-left-10">
                            {user_id > 0 ? "Cập nhật" : "Thêm mới"}
                            {is_saving && <Loader active inline size="mini" />}
                        </button>

                    </div>
                </Modal.Actions>
            </Modal>
        </div>
    );
};
