import app_config from '../app-config';
import { call_post_api } from './api_helper';

export async function select_all() {
    return call_post_api(app_config.api_url + '/campus/select_all');
};
// export async function detail(id) {
//     return call_post_api(app_config.api_url + '/role/detail',id);
// };
// export async function insert(data) {
//     return call_post_api(app_config.api_url + '/role/insert',data);
// };
// export async function update(data) {
//     return call_post_api(app_config.api_url + '/role/update',data);
// };
// export async function remove(id) {
//     return call_post_api(app_config.api_url + '/role/delete',id);
// };
