import React, { useEffect, useState } from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import UserPanel from '../user-panel/user-panel';
import './header.scss';
import { Template } from 'devextreme-react/core/template';
import { List, Popup, Image } from 'semantic-ui-react'
import { select_all as sub_system_select_all } from '../../api/sub_system'

const PopupContent = (apps) => {
  return (
    <div className="row width-400">
      {apps.map(x => {
        return (<div className="col-md-4" key={x.id}>
          <div className="system-other padding-1rem">
            <a href="#">
              <Image src={'/images/' + x.icon} className="system-other-img" circular />
              <p className="system-other-name">{x.name}</p>
            </a>
          </div>
        </div>);
      })}
    </div>
  );
}
const renderLogo = () => {
  return <a><img src="3s_logo.png" className="header-logo" style={{ height: 40, marginLeft:40 }}></img></a>;
}

export default ({ menuToggleEnabled, title, toggleMenu }) => {
  const [apps, set_apps] = useState([]);
  const reload_apps = async () => {
    const res = await sub_system_select_all();
    if (res.is_success) {
      set_apps(res.data);
    }
  }
  useEffect(() => {
    reload_apps();
  }, []);
  return (
    <header className={'header-component'}>

      <Toolbar className={'header-toolbar brand-header'}>

        {/* <Item
          visible={true}
          location={'after'}
          widget={'dxButton'}
          cssClass={'menu-button'}
        >
          <Popup
            content={() => { return PopupContent(apps) }}
            on='click'
            popper={{ id: 'popper-container', style: { zIndex: 2000 } }}
            trigger={<Button icon="menu" stylingMode="text" />}
          />

        </Item> */}
        <Item location={'before'}
          render={renderLogo}>
        </Item>
        <Item
          location={'before'}
          cssClass={'header-title'}
          // text="SMART SCHOOL SOLUTION"

        >
          <a className="header-title">SMART SCHOOL SOLUTION</a>
        </Item>
        <Item
          location={'after'}
          locateInMenu={'auto'}
          menuItemTemplate={'userPanelTemplate'}
        >
          <Button
            className={'user-button authorization'}
            width={300}
            height={'100%'}
            stylingMode={'text'}
          >
            <UserPanel menuMode={'context'} />
          </Button>
        </Item>
        <Template name={'userPanelTemplate'}>
          <UserPanel menuMode={'list'} />
        </Template>
      </Toolbar>
    </header>);
};

