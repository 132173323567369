import React, { useState, useEffect, useRef } from 'react'
import { TreeList, Selection, Column, Editing } from 'devextreme-react/tree-list';
import { Confirm, Image, Card, Checkbox } from 'semantic-ui-react'
import {
    select_by_role,
    savechanges,
    insert,
    remove
} from '../../api/role_api';


export default ({ role_id, sub_system }) => {
    const [role_apis, set_role_apis] = useState([]);
    useEffect(() => {
        if (sub_system.id > 0) {
            reload();
        }
    }, [role_id, sub_system]);

    const reload = async () => {
        const res = await select_by_role(role_id, sub_system.id);
        if (res.is_success) {
            set_role_apis(res.data);
        }
    }
    // const getSelectedKeysAll = () => {

    //     let keys = [];
    //     for (let index = 0; index < role_apis.length; index++) {
    //         const role_api = role_apis[index];
    //         getSelectedKeys(keys, role_api)
    //     }
    //     return keys;
    // }

    // const hanldeOnSelectionChanged = async (e) => {
    //     e.cancel = true;

    //     const keys = e.selectedRowsData.map(x => x.key);
    //     console.log("keys");
    //     console.log(keys);
    //     const res = await savechanges({
    //         role_id: role_id,
    //         sub_system_id: sub_system.id,
    //         menu_apis: keys
    //     });
    //     reload();
    // }
    // const getSelectedKeys = (keys, role_api) => {


    //     if (role_api.is_selected) {
    //         keys.push(role_api.key);
    //     }
    //     if (role_api.items.length > 0) {
    //         for (let index = 0; index < role_api.items.length; index++) {
    //             getSelectedKeys(keys, role_api.items[index])
    //         }
    //     }
    // }
    const hanldeOnChanged = async (key, checked) => {
        if (checked) {
            const res = await insert({
                role_id: role_id,
                sub_system_id: sub_system.id,
                menu_id: key.split('_')[0],
                api_id: key.split('_')[1]
            });
        } else {
            const res = await remove({
                role_id: role_id,
                sub_system_id: sub_system.id,
                menu_id: key.split('_')[0],
                api_id: key.split('_')[1]
            });
        }
        reload();
    }
    const cellRender = (data) => {
        if (data.data.api_id > 0 || data.data.has_apis) {
            return <div>
                <Checkbox className={data.data.has_apis ? "bold" : ""} checked={data.data.is_selected} label={data.data.name} onChange={(e, { checked }) => { hanldeOnChanged(data.data.key, checked) }}></Checkbox>
            </div>;
        } else {
            return <p className="treeview-menu-name">{data.data.name}</p>
        }

    }
    return (
        <React.Fragment>

            {sub_system.id > 0 &&
                <TreeList
                    id="role_action_grid"
                    dataSource={role_apis}
                    showRowLines={true}
                    showBorders={true}
                    columnAutoWidth={true}
                    keyExpr="key"
                    itemsExpr="items"
                    dataStructure="tree"
                    disabled={!sub_system.is_selected}
                    autoExpandAll={true}
                    height={window.innerHeight - 350 - 50}

                >

                    <Column cellRender={cellRender} caption={"Chức năng trong ứng dụng " + sub_system.name} />

                </TreeList>
            }
        </React.Fragment>
    );
}