import React, { useState } from "react";
import { Image, Icon } from 'semantic-ui-react'
import "../system/layout.css";
import RolePage from "../role/role";
import UserPage from "../user/user";
import { Link } from 'react-router-dom'
export default () => {
    const [tab, set_tab] = useState("user");
    return (
        <React.Fragment>
            <div class="p-mobile-tabs-content">

                <div class={"p-mobile-tabs--content" + (tab == "role" ? " active" : "")} id="role">
                    <RolePage></RolePage>
                </div>
                <div class={"p-mobile-tabs--content" + (tab == "user" ? " active" : "")} id="user">
                    <UserPage></UserPage>
                </div>


                <div class="p-mobile-tabs">
                    <div>
                        <Link to="/home"> <a>
                            <Icon name="home"></Icon>
                            Trang chủ
                        </a>
                        </Link>


                    </div>
                    <div>
                        <a class={tab == "user" ? "active" : ""} onClick={() => { set_tab("user") }}>
                            {/* <Image src="./images/adm.svg" circular centered></Image> */}
                            <Icon name="user"></Icon>
                            Quản lý người dùng
                        </a>
                    </div>
                    <div>
                        <a class={tab == "role" ? "active" : ""} onClick={() => { set_tab("role") }}>
                            <Icon name="cog"></Icon>

                            Quản lý vai trò
                        </a>
                    </div>


                </div>
            </div>
            {/* <div class="p-tabs-container" id="tab_example">
                <div class="p-tabs">
                    <button class="p-tab p-is-active">Service 1</button>
                    <button class="p-tab">Service 2</button>
                    <button class="p-tab">Service 3</button>
                </div>
                <div class="p-panels">
                    <div class="p-panel p-is-active">
                    </div>
                    <div class="p-panel">
                    </div>
                    <div class="p-panel">
                    </div>
                </div>
            </div> */}

        </React.Fragment>

    );
}