import React, { useReducer, useEffect, useState, useRef } from "react";
import { Transition, Icon, Confirm, Label } from 'semantic-ui-react'
import { TextBox } from 'devextreme-react/text-box';
import { select_all, remove, reset_password } from "../../api/user";
import Toolbar, { Item } from 'devextreme-react/toolbar';
import DataGrid from "../../components/datagrid/datagrid";
import DetailModal from './detail_modal';
import { Column } from 'devextreme-react/data-grid';
import notify from 'devextreme/ui/notify';
import { useForm } from "react-hook-form";
const ini_state = {
    users: [],
    selected_user: {},
}
const action_type = {
    SET_USERS: "SET_USERS",
    SET_SELECTED_USER: "SET_SELECTED_USER"
}
export default () => {
    const [is_saving, set_is_saving] = useState(false);
    const [is_loading, set_is_loading] = useState(false);
    const [is_show_modal, set_is_show_modal] = useState(false);
    const [is_show_confirm_delete, set_is_show_confirm_delete] = useState(false);
    const [is_show_modal_reset_pw, set_is_show_modal_reset_pw] = useState(false);
    const [new_pw, set_new_pw] = useState("");
    const grid = useRef(null);
    const { register, trigger, errors } = useForm();
    const handleChangePassWord = async datas => {
        trigger().then(async (isValid) => {
            if (isValid) {
                const res = await reset_password({
                    id: state.selected_user.id,
                    password: new_pw
                });
                if (res.is_success) {
                    notify("Reset mật khẩu thành công", 'success', 1000);
                    set_is_show_modal_reset_pw(false);
                } else {
                    notify(res.message, 'error', 1000);
                }
            }
        })
    }
    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case action_type.SET_USERS:
                return {
                    ...state,
                    users: action.payload,
                };
            case action_type.SET_SELECTED_USER:
                return {
                    ...state,
                    selected_user: action.payload,
                };
            default:
                return state
        }
    }, ini_state);
    useEffect(() => {
        reload_users();
    }, []);
    const reload_users = async () => {
        set_is_loading(true);
        const res = await select_all();
        set_is_loading(false);
        if (res.is_success) {
            dispatch({ type: action_type.SET_USERS, payload: res.data });
        }
    }
    const btnEdit_click = (data) => {
        dispatch({ type: action_type.SET_SELECTED_USER, payload: data });
        set_is_show_modal(true);
    }
    const btnDelete_click = (data) => {
        dispatch({ type: action_type.SET_SELECTED_USER, payload: data });
        set_is_show_confirm_delete(true);
    }
    const btnResetPW_click = (data) => {
        dispatch({ type: action_type.SET_SELECTED_USER, payload: data });
        set_is_show_modal_reset_pw(true);
    }
    const confirmDelete = async () => {
        const res = await remove(state.selected_user.id);
        if (res.is_success) {
            reload_users();
            set_is_show_confirm_delete(false);
        } else {
            notify(res.message, 'error', 1000);
        }
    }
    const cellRender = (data) => {
        return <div>
            <span style={{ cursor: "pointer" }} onClick={() => { btnEdit_click(data.data) }}><i aria-hidden="true" className="icon edit"></i> </span>
            <span style={{ cursor: "pointer" }} onClick={() => { btnDelete_click(data.data) }}><i aria-hidden="true" className="icon trash"></i> </span>
            <span style={{ cursor: "pointer" }} onClick={() => { btnResetPW_click(data.data) }}><i aria-hidden="true" className="icon unlock"></i> </span>
        </div>;
    }
    const column = [
        <Column key={0} cellRender={cellRender} caption="Thao tác" width={100}></Column>,
        <Column key={1} dataField="username" caption="Tên đăng nhập"></Column>,
        <Column key={2} dataField="full_name" caption="Họ tên"></Column>,
        <Column key={3} dataField="email" caption="Email"></Column>,
        <Column key={4} dataField="phone_number" caption="Điện thoại"></Column>,
        <Column key={5} dataField="is_active" caption="Trạng thái"></Column>,
        <Column key={6} dataField="role_names" caption="Vai trò"></Column>,
        <Column key={7} dataField="campus_names" caption="Cơ sở"></Column>
    ];

    return (
        <React.Fragment>
            <Transition animation="scale"
                visible={true}
                duration={500}
                transitionOnMount={true}>
                <div>
                    <div className="row">

                        <div className="col-md-12 padding-top-1rem padding-left-2rem">

                            <Toolbar>
                                <Item location="before"
                                    render={() => {
                                        return (
                                            <h3>Danh sách người dùng</h3>
                                        );
                                    }}
                                />
                                <Item location="after"
                                    render={() => {
                                        return (
                                            <a href="#" class="p-btn p-prim-col  p-white-color p-btn-sm" onClick={() => {
                                                set_is_show_modal(true);
                                                dispatch({ type: action_type.SET_SELECTED_USER, payload: { id: 0 } });
                                            }}  >
                                                <Icon name="plus"></Icon>
                                                Thêm nguời dùng
                                            </a>
                                        );
                                    }}
                                />
                                <Item location="after"
                                    render={() => {
                                        return (
                                            <a href="#" class="p-btn  p-btn-sm"    >
                                                <Icon name="file excel outline"></Icon>
                                                Xuất excel
                                            </a>
                                        );
                                    }}
                                />
                            </Toolbar>
                        </div>
                        <div className="col-md-12">
                            <DataGrid
                                key="id"
                                dataSource={state.users}
                                height={window.innerHeight - 170}
                                columns={column}
                                grid={grid}
                            >

                            </DataGrid>
                        </div>
                        <DetailModal is_show_model={is_show_modal}
                            onCancel={() => { set_is_show_modal(false) }}
                            onSuccess={() => { set_is_show_modal(false); reload_users(); }}
                            user_id={state.selected_user.id}
                        ></DetailModal>
                    </div>

                </div>
            </Transition>
            <Confirm
                open={is_show_confirm_delete}
                dimmer={"blurring"}
                header='Lưu ý'
                content='Bạn có chắc chắn muốn xóa người dùng ?'
                size='mini'
                cancelButton={() => {
                    return (
                        <button onClick={() => { set_is_show_confirm_delete(false) }} className="p-btn p-btn-sm">
                            Đóng
                        </button>
                    );
                }}
                confirmButton={() => {
                    return (
                        <button onClick={() => { confirmDelete() }} className="p-btn p-btn-sm p-strawberry margin-left-10 p-white-color p-white-color">
                            Xóa người dùng
                        </button>
                    );
                }}
            // onCancel={() => { set_is_show_confirm_delete(false) }}
            // onConfirm={confirmDelete}
            />
            <Confirm
                open={is_show_modal_reset_pw}
                dimmer={"blurring"}
                header='Lưu ý'
                content={() => {
                    return (
                        <React.Fragment>
                            <div className="padding-1rem">
                                <p>Bạn có chắc chắn muốn reset mật khẩu người dùng ?</p>
                                <form className="ui">
                                    <div className="row">
                                        <div className="col-md-12 padding-top-1rem">
                                            <div className="dx-field-label">Mật khẩu mới:</div>
                                            <br></br>
                                            <TextBox value={new_pw}
                                                stylingMode="outlined"
                                                onValueChanged={(e) => {
                                                    set_new_pw(e.value);
                                                    trigger("new_pw");
                                                }}
                                            />
                                            <input name="new_pw"
                                                value={new_pw}
                                                className="hide"
                                                ref={register({ required: true, minLength: 6 })}></input>
                                            {
                                                errors.new_pw &&
                                                <Label basic color='red' pointing>
                                                    Mật khẩu phải tối thiểu 6 ký tự
                                                </Label>
                                            }

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </React.Fragment>
                    );
                }}
                size='mini'
                cancelButton={() => {
                    return (
                        <button onClick={() => { set_is_show_modal_reset_pw(false) }} className="p-btn p-btn-sm">
                            Đóng
                        </button>
                    );
                }}
                confirmButton={() => {
                    return (
                        <button onClick={() => { handleChangePassWord() }} className="p-btn p-btn-sm p-strawberry margin-left-10 p-white-color p-white-color">
                            Reset mật khẩu
                        </button>
                    );
                }}
            />
        </React.Fragment>
    );
}