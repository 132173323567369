import app_config from '../app-config';
import { call_post_api } from './api_helper';

export async function select_by_role(role_id) {
    return call_post_api(app_config.api_url + '/role_sub_system/select_by_role',role_id);
};
export async function insert(data) {
    return call_post_api(app_config.api_url + '/role_sub_system/insert',data);
};

export async function remove(data) {
    return call_post_api(app_config.api_url + '/role_sub_system/delete',data);
};
