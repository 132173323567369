import { Transition } from 'semantic-ui-react';
import { withNavigationWatcher } from './contexts/navigation';
import { HomePage } from './pages';
import LayoutPage from './pages/system/layout';
const Transition_HomePage = () => {
  return (
    <Transition
      animation="scale"
      visible={true}
      duration={500}
      transitionOnMount={true}
    >
      <HomePage></HomePage>
    </Transition>
  )
}
const routes = [
  {
    path: '/role',
    component: LayoutPage
  },
  {
    path: '/home',
    component: HomePage
  }
];

export default routes.map(route => {
  return {
    ...route,
    component: withNavigationWatcher(route.component)
  };
});
