import app_config from '../app-config';
import { call_post_api } from './api_helper';

export async function select_by_role(role_id, sub_system_id) {
    return call_post_api(app_config.api_url + '/role_api/select_by_role', {
        role_id: role_id,
        sub_system_id: sub_system_id
    });
};
export async function savechanges(data) {
    return call_post_api(app_config.api_url + '/role_api/savechanges', data);
};

export async function insert(data) {
    return call_post_api(app_config.api_url + '/role_api/insert', data);
};


export async function remove(data) {
    return call_post_api(app_config.api_url + '/role_api/delete', data);
};

