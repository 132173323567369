
import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import {
    getUser,
    signIn as sendSignInRequest,
    signOut as sendSignOutRequest,
    createLoginTicket as sendCreateLoginTicket,
    signInFromApp as sendSignInFromAppRequest
} from '../api/auth';

function AuthProvider(props) {
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true);
    const [service, setService] = useState("");
    useEffect(() => {
        (async function () {
            const access_token = localStorage.access_token;
            const result = await getUser(access_token);

            if (result.is_success) {
                setUser(result.data);
            }

            setLoading(false);
        })();
    }, []);

    const signIn = useCallback(async (username, password, service, system_id) => {

        const result = await sendSignInRequest(username, password, service, system_id);
        if (result.is_success && result.data != null) {
            set_jwt_token(result.message);
            setUser(result.data);
            setService(service);
        } else {
            clear_jwt_token();

        }

        return result;
    }, []);
    const set_jwt_token = (token) => {
        const access_token = token.split(' ')[0];
        const refresh_token = token.split(' ')[1];

        //const data = jwt_decode(access_token);

        
        localStorage.access_token = access_token;
        localStorage.refresh_token = refresh_token;

    }
    const clear_jwt_token = () => {
        localStorage.access_token = null;
        localStorage.refresh_token = null;
    }
    const signInFromApp = useCallback(async (data) => {
        const result = await sendSignInFromAppRequest({ ...data });

        if (result.is_success && result.data != null) {
            //localStorage.token = result.message;
            set_jwt_token(result.message);

            setUser(result.data);
            setService(data.service);

        } else {
            clear_jwt_token();

        }

        return result;
    }, []);


    const createLoginTicket = useCallback(async (service, system_id) => {
        const result = await sendCreateLoginTicket(service, system_id);
        if (result.is_success) {
            return result;
        }
        return null;
    }, []);

    const signOut = useCallback(async () => {

        const result = await sendSignOutRequest();
        clear_jwt_token();
        setUser();

    }, []);


    return (
        <AuthContext.Provider value={{
            user, signIn, signOut, loading, service,
            createLoginTicket, signInFromApp
        }} {...props} />
    );
}

const AuthContext = createContext({});
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }


