import app_config from '../app-config';
import { call_post_api } from './api_helper';

export async function select_all() {
    return call_post_api(app_config.api_url + '/user/all');
};
export async function detail(id) {
    return call_post_api(app_config.api_url + '/user/detail',id);
};
export async function insert(data) {
    return call_post_api(app_config.api_url + '/user/insert',data);
};
export async function update(data) {
    return call_post_api(app_config.api_url + '/user/update',data);
};
export async function remove(id) {
    return call_post_api(app_config.api_url + '/user/delete',id);
};
export async function change_password(data) {
    return call_post_api(app_config.api_url + '/change_password',data);
};
export async function reset_password(data) {
    return call_post_api(app_config.api_url + '/user/reset_pw',data);
};