import React, { useReducer, useState, useEffect } from 'react'
import { Icon, Image, Card, Checkbox } from 'semantic-ui-react'
import { select_all as select_all_role, remove as remove_role } from '../../api/role'
import {
    select_by_role as select_subsytem_by_role
} from "../../api/role_subsystem";
import { List } from 'devextreme-react/list'

import DetailModal from './detail_modal'
import SubSytemSelection from './role_sub_system_selection'
import ActionGrid from './role_action_grid'
import '../role/role.css'
import { Transition } from 'semantic-ui-react'
export default () => {
    const [is_show_model_detail, set_is_show_model_detail] = useState(false);
    
    const ini_state = {
        roles: [],
        editing_role: {},
        sub_systems: [],
        editing_sub_system: {}
    }
    const action_type = {
        KHOI_TAO: "KHOI_TAO",
        SET_ROLES: "SET_ROLES",
        SET_EDITING_ROLE: "SET_EDITING_ROLE",
        SET_SUB_SYSTEM: "SET_SUB_SYSTEM",
        SET_EDITING_SUB_SYSTEM: "SET_EDITING_SUB_SYSTEM"
    }
    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case action_type.KHOI_TAO:
                return action.payload;
            case action_type.SET_ROLES:
                return {
                    ...state,
                    roles: action.payload,
                    // editing_role: {},
                    // sub_systems: [],
                    // editing_sub_system: {}
                };
            case action_type.SET_EDITING_ROLE:
                return {
                    ...state,
                    editing_role: action.payload,
                    // sub_systems: [],
                    // editing_sub_system: {}
                };
            case action_type.SET_SUB_SYSTEM:
                return {
                    ...state,
                    sub_systems: action.payload,
                    // editing_sub_system: {}
                };
            case action_type.SET_EDITING_SUB_SYSTEM:
                return {
                    ...state,
                    editing_sub_system: action.payload
                };
            default:
                return state
        }
    }, ini_state);

    const get_roles = async () => {
        const res = await select_all_role();
        if (res.is_success) {
            return res.data;
        }
        return [];
    }
    const reload = async () => {
        const roles = await get_roles();
        dispatch({ type: action_type.SET_ROLES, payload: roles });
    }
    const reload_sub_system = async (role_id) => {

        const res = await select_subsytem_by_role(role_id);
        if (res.is_success) {
            dispatch({ type: action_type.SET_SUB_SYSTEM, payload: res.data });
        } else {
            dispatch({ type: action_type.SET_SUB_SYSTEM, payload: [] });
        }
    }
    const handleSubSystemChanged = async (sub_system_id) => {

        const sub_systems = state.sub_systems.filter(x => x.id == sub_system_id);

        if (sub_systems.length > 0) {
            dispatch({ type: action_type.SET_EDITING_SUB_SYSTEM, payload: sub_systems[0] });
        }

    }
    const handleListSelectionChange = (e) => {
        if (e.addedItems.length > 0) {
            // console.log(e.addedItems[0]);
            dispatch({ type: action_type.SET_EDITING_ROLE, payload: e.addedItems[0] });
            reload_sub_system(e.addedItems[0].id);
        }
    }
    const onItemDeleting = async (e) => {
        //console.log(e);
        e.cancel = true;
        const role_id = e.itemData.id;
        const res = await remove_role(role_id);
        if (res.is_success) {
            dispatch({ type: action_type.SET_EDITING_SUB_SYSTEM, payload: {} });
            dispatch({ type: action_type.SET_SUB_SYSTEM, payload: [] });
            dispatch({ type: action_type.SET_EDITING_ROLE, payload: {} });
            reload();
        }


    }
    useEffect(() => {
        reload();
    }, []);
    const renderListItem = (item) => {
        return (
            <div>
                <div className="role">
                    <a onClick={() => { }} className="role-name">
                        {item.name}
                    </a>
                    <div className="role-description">{item.ghi_chu}</div>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Transition
                animation="scale"
                visible={true}
                duration={500}
                transitionOnMount={true}
            >
                <div>
                    <div className="row">
                        <div className="col-md-4 col-lg-2 bg-grey padding-2rem padding-bottom-0">
                            <h3 className="margin-bottom-30">Vai trò</h3>
                            <List
                                selectionMode="single"
                                height={window.innerHeight - 180 -50}
                                dataSource={state.roles}
                                searchEnabled={false}
                                onSelectionChanged={handleListSelectionChange}
                                itemRender={renderListItem}
                                // elementAttr={listAttrs}
                                allowItemDeleting={true}
                                itemDeleteMode="toggle"
                                className="role_list"
                                onItemDeleting={onItemDeleting}
                            />
                            <div>
                                <a href="#" class="p-btn p-prim-col p-fluid p-white-color p-btn-sm" onClick={() => {
                                    dispatch({ type: action_type.SET_EDITING_ROLE, payload: { id: 0 } });
                                    set_is_show_model_detail(true)
                                }}>
                                    <Icon name="plus"></Icon>
                            Thêm vai trò
                        </a>
                            </div>
                        </div>
                        <div className="col-md-8 col-lg-10">
                            {(state.editing_role == null || state.editing_role.id == undefined || state.editing_role.id == 0)
                                &&
                                <React.Fragment>
                                    <div className="homescreen" style={{ height: window.innerHeight - 100 }}>
                                        <div className="vertical-center width-100-p text-align-center">
                                            <h2 className="text-align-center text-mute">chọn một vai trò để phân quyền</h2>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                            {state.editing_role.id > 0 &&
                                <React.Fragment>
                                    <div className="row">
                                        <div className="col-md-12 padding-2rem">


                                            <div className="card-group-list">
                                                <h3 className="padding-top-1rem text-align-center">
                                                    Chọn các phân hệ, chức năng được sử dụng bởi <b>{state.editing_role.name}</b>
                                                    <a onClick={() => { set_is_show_model_detail(true) }} className="margin-left-5"><Icon name="edit outline"></Icon></a>
                                                </h3>
                                                <Card.Group centered itemsPerRow={window.innerWidth > 1200 ?10: 6}>
                                                    {state.sub_systems.map(x => {
                                                        return (
                                                            <SubSytemSelection
                                                                onChanged={async (role_id, sub_system_id) => {
                                                                    await reload_sub_system(role_id);
                                                                }}
                                                                onClick={handleSubSystemChanged}
                                                                role_sub_system={x}
                                                                key={x.id}
                                                                is_focused={state.editing_sub_system.id == x.id}
                                                            >

                                                            </SubSytemSelection>
                                                        );
                                                    })}
                                                </Card.Group>
                                            </div>

                                        </div>
                                        <div className="col-md-12 padding-2rem">
                                            <ActionGrid
                                                role_id={state.editing_role.id}
                                                sub_system={state.editing_sub_system}
                                            >
                                            </ActionGrid>
                                        </div>
                                    </div>

                                </React.Fragment>
                            }
                        </div>
                        {/* {JSON.stringify(state.editing_role)} */}
                        <DetailModal
                            is_show_model={is_show_model_detail}
                            role_id={state.editing_role.id}
                            onCancel={() => { set_is_show_model_detail(false) }}
                            onSuccess={() => { set_is_show_model_detail(false); reload(); }}
                        ></DetailModal>
                    </div>
                </div>
            </Transition>


        </React.Fragment >
    );
}