import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import './home.scss';
import { Modal, Image } from 'semantic-ui-react'
import TileView from 'devextreme-react/tile-view';
import { select_all as sub_system_select_all } from '../../api/sub_system'
import { change_password } from '../../api/user'
import { useHistory } from 'react-router';
import { Transition } from 'semantic-ui-react';
import { useAuth } from '../../contexts/auth';
import notify from 'devextreme/ui/notify';
import LoadIndicator from 'devextreme-react/load-indicator';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
} from 'devextreme-react/form';


const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}



const Home = () => {
  const history = useHistory();
  const [is_show_modal_pw, set_is_show_modal_pw] = useState(false);
  const width = window.innerWidth;
  const [apps, set_apps] = useState([]);
  const { user, signOut } = useAuth();
  const [itemConfig, setItemConfig] = useState({
    itemPerRow: 0,
    containerWidth: 0,
    itemMargin: 0,
    baseItemWidth: 0,
    baseItemHeight: 0,
  });
  useEffect(() => {
    Ini();
    reload_apps();
  }, []);
  const Ini = () => {
    let result = {};
    if (width >= 1300) {

      result.itemPerRow = 5;
      result.containerWidth = width;
      result.baseItemWidth = width / 13;
      result.baseItemHeight = width / 16;
      result.itemMargin = (width - result.baseItemWidth * (result.itemPerRow + 2)) / (result.itemPerRow + 3);
      setItemConfig(result);
      return;
    }
    if (width >= 576) {

      result.itemPerRow = 5;
      result.containerWidth = width;
      result.baseItemWidth = width / 13;
      result.baseItemHeight = width / 13;
      result.itemMargin = (width - result.baseItemWidth * (result.itemPerRow + 2)) / (result.itemPerRow + 3);
      setItemConfig(result);
      return;
    } else {
      result.itemPerRow = 3;
      result.containerWidth = width;
      result.baseItemWidth = 60;
      result.baseItemHeight = 60;
      result.itemMargin = (width - result.baseItemWidth * (result.itemPerRow)) / (result.itemPerRow + 1);
      setItemConfig(result);
      return;
    }
  }
  const reload_apps = async () => {
    set_apps(user.sub_systems);
  }
  function TileViewItem({ data }) {
    return (
      <Transition
        animation="zoom"
        visible={true}
        duration={500}
        transitionOnMount={true}>
        <div className={"dx-tile-content" + (data.is_accessed ? "" : " app-disabled ")} onClick={() => {
          if (data.is_accessed) {
            if (data.url) {
              if (data.url.split('/')[0].includes('http')) {
                  window.location.href = data.url + '/login?user=' + user.id;
              }
              else {
                history.push(data.url);
              }
            }

          }
        }}>
          <div className="app-item">
            <a>
              <Image src={'/images/' + data.icon} className="app-icon" />
              <p className="app-name" style={{ fontSize: itemConfig.baseItemWidth / 7 }}>{data.name} </p>
            </a>
          </div>

        </div>
      </Transition>
    );
  }
  return (
    <React.Fragment>
      <div>
        <div className="homescreen" style={{ height: window.innerHeight - 100 }}>
          <div className="vertical-center width-100-p text-align-center">

            <p className="homescreen-appname width-100-p" style={{ fontSize: (itemConfig.baseItemWidth / 4) }}>Xin chào, {user.full_name}</p>
            
            <a onClick={() => { set_is_show_modal_pw(true) }} className="homescreen-setting-account width-100-p" style={{ fontSize: (itemConfig.baseItemWidth / 5.5) }}>Đổi mật khẩu
        <span className="homescreen-setting-account-arrow">
                <svg viewBox="0 0 47.75390625 84.9111328125" style={{ width: 7 }}
                >
                  <g transform="matrix(1 0 0 1 -12.451191406250018 77.685546875)">
                    <path d="M 16.8945 7.17773 C 18.1641 7.17773 19.2383 6.68945 20.0684 5.85938 L 58.7891 -31.9824 C 59.7168 -32.9102 60.2051 -34.0332 60.2051 -35.2539 C 60.2051 -36.5234 59.7168 -37.6953 58.7891 -38.5254 L 20.1172 -76.3184 C 19.2383 -77.1973 18.1641 -77.6855 16.8945 -77.6855 C 14.3555 -77.6855 12.4512 -75.7324 12.4512 -73.1934 C 12.4512 -72.0215 12.9395 -70.8496 13.7207 -70.0195 L 49.2676 -35.2539 L 13.7207 -0.488281 C 12.9395 0.341797 12.4512 1.46484 12.4512 2.68555 C 12.4512 5.22461 14.3555 7.17773 16.8945 7.17773 Z"></path></g></svg></span>
            </a>

            {itemConfig.containerWidth > 1200 && itemConfig.baseItemWidth > 0 &&
              <div style={{
                marginLeft: (itemConfig.baseItemWidth + itemConfig.itemMargin),
                marginRight: (itemConfig.baseItemWidth + itemConfig.itemMargin)
              }}>
                <TileView items={apps}
                  baseItemHeight={itemConfig.baseItemHeight}
                  baseItemWidth={itemConfig.baseItemWidth}
                  itemMargin={itemConfig.itemMargin}
                  itemComponent={TileViewItem}
                  direction="vertical"
                  height={window.innerHeight * 0.6}
                  width={(itemConfig.baseItemWidth + itemConfig.itemMargin) * itemConfig.itemPerRow + itemConfig.itemMargin}
                />
              </div>
            }
            {itemConfig.containerWidth <= 1200 && itemConfig.baseItemWidth > 0 &&
              <TileView items={apps}
                baseItemHeight={itemConfig.baseItemHeight}
                baseItemWidth={itemConfig.baseItemWidth}
                itemMargin={itemConfig.itemMargin}
                itemComponent={TileViewItem}
                direction="vertical"
                width='100%'
              />
            }
          </div>

        </div>
        <div className="legal-footer">
          <div className="legal-footer-content">
            <span>
              <a target="_blank" href="https://3s.edu.vn/">Copyright ©2021 - 3s.edu.vn - Công ty CP Công nghệ 3S</a>  |
            <a target="_blank" href="tel:0337712389"> Hotline: 033.77.12389</a>  |
            <a target="_blank" href="mailto:info@3s.edu.vn"> Email: info@3s.edu.vn</a>
            </span>
          </div>
        </div>
      </div>
      {is_show_modal_pw &&
        <ModalChangePassword onCancle={() => { set_is_show_modal_pw(false) }}
          onSuccess={() => { set_is_show_modal_pw(false) }}
        />
      }

    </React.Fragment>)
}

const ModalChangePassword = ({ onSuccess, onCancle }) => {
  const [loading, setLoading] = useState(false);
  const [message, set_message] = useState("");
  const formData = useRef({});
  const onSubmit = async (e) => {
    e.preventDefault();
    const { old_password, new_password, new_password_retype } = formData.current;
    if (new_password != new_password_retype) {
      set_message("Nhập lại mật khẩu không khớp");
      return false;
    } else {
      setLoading(true);
      const result = await change_password({
        old_password: old_password,
        new_password: new_password
      });
      setLoading(false);
      if (!result.is_success) {
        set_message(result.message);
      } else {
        notify("Đổi mật khẩu thành công", 'success', 1000);
        onSuccess();
      }
    }
  }
  return (
    <React.Fragment>


      <Modal closeOnEscape={false} closeOnDimmerClick={false} open={true} size="mini" dimmer={"blurring"} onClose={() => { onCancle() }} closeIcon className="modal-login">
        <Modal.Content>

          <Image src="./images/3s.png" className="logo-login"></Image>
          <h4 className="text-align-center">Đổi mật khẩu</h4>
          <form className={'login-form'} onSubmit={onSubmit}>
            <Form formData={formData.current} >
              <Item
                dataField={'old_password'}
                editorType={'dxTextBox'}
                editorOptions={{ stylingMode: 'outlined', placeholder: 'Mật khẩu hiện tại', mode: 'password' }}
              >
                <RequiredRule message="Vui lòng điền mật khẩu hiện tại" />
                <Label visible={false} />
              </Item>
              <Item
                dataField={'new_password'}
                editorType={'dxTextBox'}
                editorOptions={{ stylingMode: 'outlined', placeholder: 'Mật khẩu mới', mode: 'password' }}
              >
                <RequiredRule message="Vui lòng điền mật khẩu mới" />
                <Label visible={false} />
              </Item>
              <Item
                dataField={'new_password_retype'}
                editorType={'dxTextBox'}
                editorOptions={{ stylingMode: 'outlined', placeholder: 'Nhập lại mật khẩu mới', mode: 'password' }}
              >
                <RequiredRule message="Vui lòng nhập lại mật khẩu mới" />
                <Label visible={false} />
              </Item>
              <ButtonItem className="btn-submit">

                <ButtonOptions
                  width={'100%'}
                  type={'default'}
                  useSubmitBehavior={true}
                >
                  <span className="dx-button-text">
                    {
                      loading
                        ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                        : 'Đổi mật khẩu'
                    }
                  </span>

                </ButtonOptions>
              </ButtonItem>

            </Form>
          </form>
          <Transition
            animation="zoom"
            visible={true}
            duration={500}
          >
            <h6 className="login-error-message">
              {message}
            </h6>
          </Transition>
        </Modal.Content>

      </Modal >
    </React.Fragment>
  );
}

export default Home;